import { combineReducers } from "redux"
import client from "./client/reducer"
import admin from "./admin/reducer"
import authentication from "./authentication/reducer"
import verify from "./verify/reducer"
import relative from "./relative/reducer"
import nir from "./nir/reducer"
import doctor from "./doctor/reducer"
import socket from "../services/socket/reducer"
import survey from "../pages/Survey/services/reducer"

const createRootReducers = (history: any) =>
  combineReducers({
    client,
    admin,
    authentication,
    verify,
    relative,
    nir,
    survey,
    doctor,
    socket,
    router: history,
  })

const RootReducer = (history: any) => (state: any, action: any) => {
  if (action.type === "RESET_STORE") {
    const { clinic } = state.client
    const { router } = state
    const initialState = {
      client: { clinic, success: true },
      router,
    }
    state = initialState
  }
  return createRootReducers(history)(state, action)
}

export default RootReducer
