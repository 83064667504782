import React from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Dispatch } from "redux"

import { withHeaderAndFooter } from "../../components/hocs"
import { CHECK_PHONE, SURVEY_PAGE } from "../../core/constants"
import { setPatient } from "../../services/client/actions"
import { ClientStore } from "../../types/types"
import { FatButton } from "../../components/generics";
import PreviousButton from "../../components/new/Previous/PreviousButton";
import { VALIDATION_STEP } from "../../types/contants";
import buttonStyles from "../../components/generics/FatButton/FatButton.module.scss";
import styles from "../../components/new/Modal/Modal.module.scss";
import {languages} from "../../lib/languages";

interface IsThisYourAccountProps {
  phone?: string
  unsetPatient: () => void
}
const IsThisYourAccount = (props: IsThisYourAccountProps) => {
  const navigate = useNavigate()

  const onAcceptThisPatient = () => {
    navigate(SURVEY_PAGE)
  }

  const createNewAccount = () => {
    props.unsetPatient()
    navigate(CHECK_PHONE, { state: { step: VALIDATION_STEP.PHONE } })
  }

  return (
    <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <PreviousButton reset={true}/>

      <h1 className="title-card align-center">
        <span>{ languages.isThisYourAccount }</span>
        <br/>
        <span className="error-color">{props.phone}</span><span> ?</span>
      </h1>

      <FatButton
        type={"primary"}
        big={true}
        onClick={onAcceptThisPatient}
      >
        <span className={buttonStyles.SmallText}>
          { languages.yesComma }
          <br/>
          { languages.itIsMyAccount }
        </span>
      </FatButton>

      <span
        style={{ textDecoration: "underline", marginTop: "50px", textDecorationSkipInk: "none"}}
        className={`${styles.leaveLinkConsent}`}
        onClick={createNewAccount}
      >
        { languages.itIsNotMyAccount }
      </span>
    </div>
  )
}

const s = (globalStore: {
  client: ClientStore,
}): Partial<IsThisYourAccountProps> => {
  return {
    phone: globalStore.client.patient?.phone,
  }
}

const p = (dispatch: Dispatch): Partial<IsThisYourAccountProps> => {
  return {
    unsetPatient: () => dispatch(setPatient(null)),
  }
}
export default connect(s, p)(withHeaderAndFooter(IsThisYourAccount) as any)
