import ClientSaga from "./client/saga"
import AdminSaga from "./admin/saga"
import RegisterSaga from "./authentication/saga"
import VerifySaga from "./verify/saga"
import RelativeSaga from "./relative/saga"
import NirSaga from "./nir/saga"
import SocketSaga from "./socket/saga"
import DoctorSaga from "./doctor/saga"
import SurveySaga from "../pages/Survey/services/saga"
import { all } from "redux-saga/effects"

export default function* IndexSaga() {
  yield all([
    ClientSaga(),
    AdminSaga(),
    RegisterSaga(),
    VerifySaga(),
    RelativeSaga(),
    NirSaga(),
    DoctorSaga(),
    SocketSaga(),
    SurveySaga(),
  ])
}
