// Answer API
import { Dayjs } from "dayjs";

////////////////////////////////////
export interface InstallationInfos {
  installation_identifier: string
  app_version: string
  device_type: string
  device_model: string
  os_version: string
  badge: string
  locale_identifier: string
  time_zone: string
  is_active: boolean
  is_release: boolean
  bundle: string
}
export interface AdminInfos {
  email: string
  password: string
}
export interface VerifyInfos {
  phone: string
  code: string
}
export interface LoginInfos {
  phone: string
  password: string
}

export interface OrderRequest {
  relativeId: string
  doctorId: string
  clinicId?: string
  slot: string
  anamnese: any
}

// Response API
////////////////////////////////////
export interface ResponseInstallation {
  customer: Patient | null
  doctor: Doctor | null
  jwt: string
  pharmacy: Clinic | null
  status: string
  // Maybe not ?
  relatives?: Relative[] | null
  relative?: Relative
  prospect: Prospect
}

export interface ResponseAdmin {
  // @TODO fill
  pharmacy: Clinic | null
  status: string
}
export interface ResponseNir {
  data: DataCarteVitale | {}
  status: CarteVitaleStatus
  updated_at: ""
}
export interface ResponseLogin {
  // @TODO fill
  customer: Patient | null
  status: string
}
export interface ResponseRegister {
  customer: Patient | null
  status: string
  // @TODO fill
}
export interface ResponseInsertRelative {
  // @TODO fill
  relative: Relative
  status: string
}
export interface ResponseGetRelatives {
  relatives: []
  status: "ok"
}
export interface ResponseVerify {
  // @TODO fill
  customer: Patient
  error?: string
}
export interface ResponceVerifyError {
  error: string | ""
  path: string | ""
}

export interface CreateAccountPayloadType {
  password?: string
  phone?: string
}

export interface ResponseDoctorList {}
export interface CustomerPersonnalData {
  gender: string | null
  firstname: string | ""
  lastname: string | ""
  birthdate: string | ""
  nir?: string | ""
  email: string | ""
}

export interface CreateAccountFullPayloadType extends CreateAccountPayloadType {
  gender: string | null
  birthdate: string
  firstname: string
  lastname: string
  email?: string
}

export interface UpdatePatientPayloadType {
  gender?: string
  firstname?: string
  lastname?: string
  birthdate?: string
  nir?: string
  phone?: string
  "x-patient-token"?: string
}

export interface ResponseNirBeta {
  isRegistered: boolean
  customer: Partial<Patient> | null
  status: string
}

export interface ResponseManualyGrabNir {
  relative: Partial<Relative> | null
  customer: Partial<Patient> | null
  status: string
}

export interface PayloadError {
  code: number
  details: any
  error: ERROR_API
  status: string
}
export interface ResponseError {
  message: string
}

export enum ERROR_API {
  MISMATCHING_INFORMATION = "MISMATCHING_INFORMATION",
  PHONE_EXISTING_ERROR = "PHONE_EXISTING_ERROR",
  PAYLOAD_ERROR = "PAYLOAD_ERROR",
  EXISTING_ORDER = "EXISTING_ORDER",
  NIR_LENGTH = "PAYLOAD_ERROR",
  NEXMO_ERROR = "NEXMO_ERROR",
}

// Store
////////////////////////////////////
export interface AuthStore {
  success: boolean
  loading: boolean
  message?: string
}

export interface Prospect {
  fromNir?: boolean
  birthdate?: any
  email?: string
  firstname?: string | null
  first_birth_firstname?: string | null
  gender?: number | null
  sex?: number | null
  lastname?: string | null
  birth_lastname?: string | null
  insee_code?: string | number | null
  birth_location?: string
  birth_country?: string
  nir?: string | boolean
  password?: string | null
  phone?: string | null
  relatives?: Array<any>
  "x-patient-token"?: string | ""
}

export interface LoginData {
  phone: string
}

export interface ClientStore extends AuthStore {
  patient: Patient | null | any
  clinic: Clinic | null
  relatives: Relative[] | null
  prospect: Prospect | null
  doctors: Doctor[] | []
  createAccountState: {
    loading: boolean
  }
}
export interface LoginStore extends AuthStore {}
export interface VerifyStore extends AuthStore {
  phone?: string | null
  retry?: {
    [x: string]: number
  }
  error?: {
    [x: string]: string
  }
  currentStep: number
  isAdminPasswordValid: boolean
  modal: {
    loading: boolean
  }
}
export interface AdminStore extends AuthStore {}

// OBJECTS TYPE
////////////////////////////////////
export enum ORDER_QUESTION_STATUS {
  QUERING = 0,
  ASKING = 1,
  ANSWERED = 2,
}
export type ANAMNESE_QUESTION_STATUS = ORDER_QUESTION_STATUS

export enum ORDER_QUESTION_STEP {
  FOR_WHO = "patient",
  WHICH_WHO = "docteur",
  WHICH_TIME = "creneau",
  ANAMNESE = "anamnese",
}
export enum ANAMNESE_QUESTION_STEP {
  INITIALIZE = "initialize",
  ILLNESS = "illness",
  OPERATIONS = "operations",
  ALLERGIES = "allergies",
  PREGNANT = "pregnant",
  DRUGS = "drugs",
}
export enum ANAMNESE_QUESTION_STEP_TEST {
  INITIALIZE = "initialize",
  ILLNESS = "illness",
  OPERATIONS = "operations",
  ALLERGIES = "allergies",
  DRUGS = "drugs",
}

export enum DETAILS_ANAMNESE_QUESTION_STEP {
  ALLERGIES_DETAILS = "allergies_details",
  ILLNESS_DETAILS = "illness_details",
}

export interface OrderQuestion {
  imgUrl?: string
  title: string
  subtitle?: string
  replies: Array<ReplyOrderQuestion>
  status: ORDER_QUESTION_STATUS
  required?: boolean
  subQuestion?: OrderQuestion
  multipleReplies?: boolean
  multipleRepliesButton?: string
  goTo?: number
  carousel?: boolean
}

export enum ACTION_REPLY {
  REDIRECT = 0,
  DEFAULT = 1,
  SUBQUESTION = 2,
}

export interface ReplyOrderQuestion {
  action: ACTION_REPLY
  text: string
  value: ReplyOrderValue
  imgUrl?: string
  type?: string
}

export type ReplyOrderValue =
  | string
  | number
  | number[]
  | string[]
  | boolean
  | boolean[]
  | ReplyOrderValue[]

export interface RegisterRequest {
  birthdate: string
  password: string
  email: string
  firstname: string
  lastname: string
  phone: string
  gender: Gender
  sex: Gender
  nir?: string
}
export interface RelativeRequest {
  birthdate: string
  firstname: string
  lastname: string
  gender: Gender
  sex: Gender
  nir?: string
}

export interface Relative {
  id: string | ""
  birthdate: string
  created_at?: Date
  firstname: string
  lastname: string
  nir: boolean
  gender?: Gender
  sex?: Gender
  updated_at?: Date
}
export interface Patient {
  id: string
  birthdate: string
  created_at: string
  email: string
  firstname: string
  is_verified: boolean
  lastname: string
  nir: boolean | string
  phone: string
  gender: Gender
  updated_at: string
  token?: string
  sex: Gender
}
export type RelativeApi = Omit<Relative, "gender"> & { sex?: boolean }
export enum Gender {
  MALE = 0,
  FEMALE = 1,
}

export interface Doctor {
  id: string
  firstname: string
  lastname: string
  rpps: string
  category_id: number
}
export interface RetrieveAccountByNameAndPhoneType {
  lastname: string
  phone?: string
}

export interface RetrieveAccountByNameAndNir {
  lastname: string
  nir?: string
}

export interface Doctor {}

export interface Clinic {
  id: string
  error: string | number | null
}

// NIR TYPE
////////////////////////////////////
export interface DataCarteVitale {
  T_AsnDonneesVitale?: {
    listeBenef?: {
      T_AsnBeneficiaire?: T_AsnBeneficiaire | T_AsnBeneficiaire[]
    }
    tech?: { numSerie?: string }
  }
}
export interface T_AsnBeneficiaire {
  amc?: Amc
  amo?: Amo
  ident?: Ident
  listeAt?: ListeAt
}

export interface ListeAt {
  at1?: At
  at2?: At
  at3?: At
}
export interface At {
  codeBudget?: string
  identifiant?: string
  orgGestion?: string
}
export interface Amc {
  numAdherent?: string
  numComplB2?: string
  numComplEDI?: string
  validiteDonnees?: string
}

export interface Amo {
  caisse?: string
  centreCarte?: string
  centreGestion?: string
  codeGestion?: string
  codeRegime?: string
  listePeriodesDroits?: { T_AsnPeriode?: T_AsnPeriode }
  medecinTraitant?: string
  qualBenef?: number
}

export interface T_AsnPeriode {
  debut?: string
  fin?: string
}
export interface Ident {
  dateCertification?: string
  naissance?: { date?: string; dateEnCarte?: string }
  nir?: string // Numéro de la carte
  nirCertifie?: string
  nomUsuel?: string
  prenomUsuel?: string
  rangDeNaissance?: number
}

export enum CarteVitaleStatus {
  SUCCESS = "ok",
}
