// INSTALLATION
export const INSTALLATION_REQUESTING = "INSTALLATION_REQUESTING"
export const INSTALLATION_SUCCESS = "INSTALLATION_SUCCESS"
export const INSTALLATION_ERROR = "INSTALLATION_ERROR"

export const LOGOUT = "LOGOUT"

export const SET_CLINIC = "SET_CLINIC"
export const UNSET_CLINIC = "UNSET_CLINIC"

export const SET_PATIENT = "SET_PATIENT"
export const UNSET_PATIENT = "UNSET_PATIENT"
export const SET_PHONE = "SET_PHONE"

export const SET_RELATIVES = "SET_RELATIVES"
export const SET_PROSPECT = "SET_PROSPECT"

export const UPDATE_PATIENT_STORE = "UPDATE_PATIENT_STORE"
export const UPDATE_PATIENT_SAGA = "UPDATE_PATIENT_SAGA"
export const UPDATE_PROSPECT = "UPDATE_PROSPECT"
// export const SET_DOCTOR = "SET_DOCTOR"
// export const UNSET_DOCTOR = "UNSET_DOCTOR"
